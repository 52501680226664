body {
  background: #090920 url("./bg.png") no-repeat top left;
  background-attachment: fixed;

  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  body {
    background: #090920 url("./sm-bg.jpg") no-repeat top left;
    background-attachment: fixed;
  }
}
